<template>
    <a-modal
        :visible="visible"
        :title="`${!form.id ? 'Tambahkan' : 'Perbarui'} - Jadwal Kunjungan Salesman`"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="70%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @keydown="form.onKeydown($event)"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- bulan -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Pilih Bulan"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="month"
                        has-feedback>
                        <AMonthPicker
                            width="100%"
                            v-model:value="form.month"
                            format="MMMM YYYY"
                            value-format="YYYY-MM"
                            :disabled-date="disabledDate"
                            style="width:300px"
                            placeholder="Pilih Bulan"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- salesman -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Salesman"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="salesman_id"
                        has-feedback>
                        <FilterSalesmanV2
                            style="width: 100%"
                            v-model:value="form.salesman_id"
                            :join-options="
                                form.salesman_id
                                    ? [{ salesman_id: item.id_salesman, salesman_nopeg: item.nopeg_salesman, salesman_name: item.nama_salesman }]
                                    : []"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- toko -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Toko"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="customer_id"
                        has-feedback>
                        <FilterSalesmanToko
                            style="width: 100%"
                            v-model:value="form.customer_id"
                            v-model:sales_id="form.salesman_id"
                            :join-options="
                                form.customer_id
                                    ? [{ id: item.id_toko, code: item.kode_toko, name: item.nama_toko }]
                                    : []"/>
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- weekly -->
            <a-form-item
                class="my-2"
                label="Mingguan"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required>
                <ACard>
                    <section class="ant-form ant-form-vertical">
                        <div class="row">
                            <div
                                v-for="(item, index) in state.weekly"
                                :key="index"
                                class="col-sm">
                                <a-form-item
                                    :label="`Minggu Ke-${index + 1}`"
                                    :name="['weekly', index, item.name]"
                                    has-feedback>
                                    <ARadioGroup
                                        class="ant-radio"
                                        v-model:value="form.weekly[item.name]">
                                        <ARadioButton
                                            class="ant-radio-primary"
                                            :value="true">Y</ARadioButton>
                                        <ARadioButton
                                            class="ant-radio-danger"
                                            :value="false">N</ARadioButton>
                                    </ARadioGroup>
                                </a-form-item>
                            </div>
                        </div>
                    </section>
                </ACard>
            </a-form-item>

            <!-- daily -->
            <a-form-item
                class="my-2"
                label="Harian"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required>
                <ACard>
                    <section class="ant-form ant-form-vertical">
                        <div class="row">
                            <div
                                v-for="(item, index) in state.daily"
                                :key="index"
                                class="col-sm">
                                <a-form-item
                                    :label="item.label"
                                    :name="['daily', index, item.name]"
                                    has-feedback>
                                    <ARadioGroup
                                        class="ant-radio"
                                        v-model:value="form.daily[item.name]">
                                        <ARadioButton
                                            class="ant-radio-primary"
                                            :value="true">Y</ARadioButton>
                                        <ARadioButton
                                            class="ant-radio-danger"
                                            :value="false">N</ARadioButton>
                                    </ARadioGroup>
                                </a-form-item>
                            </div>
                        </div>
                    </section>
                </ACard>
            </a-form-item>

            <a-row class="form-row" type="flex" justify="end">
                <a-col>
                    <a-form-item>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </AForm>

        <div v-if="form.errors.any()">
            <ASpace>
                <AAlert
                    v-for="(item, index) in form.errors.all()"
                    type="error"
                    :key="index"
                    :message="item.message"
                    banner
                    closable
                    @close="form.clear()" />
            </ASpace>
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
    watch,
    nextTick,
} from 'vue'
import Form from 'vform'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterSalesmanToko from '@/components/filter/FilterSalesmanToko'
import FilterSalesmanV2 from '@/components/filter/FilterSalesmanV2'
import moment from 'moment'
import { isEmpty, pick, toInteger } from 'lodash'

export default defineComponent({
    components: {
        FilterSalesmanToko,
        FilterSalesmanV2,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            customer_id: [],
            salesman_id: [],
            month: null,
            weekly: {
                w1: false,
                w2: false,
                w3: false,
                w4: false,
                w5: false,
                w6: false,
            },
            daily: {
                senin: false,
                selasa: false,
                rabu: false,
                kamis: false,
                jumat: false,
                sabtu: false,
                minggu: false,
            },
            edit_status: false,
        }))

        const formRef = ref();

        const state = reactive({
            endpoint: {
                current: '/api/visit-plan',
                check: '/api/visit-plan/cek-existing-log',
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                month: [
                    {
                        required: true,
                        message: "Bulan tidak boleh kosong!",
                    },
                ],
                customer_id: [
                    {
                        required: true,
                        message: "Toko tidak boleh kosong!",
                    },
                ],
                salesman_id: [
                    {
                        required: true,
                        message: "Salesman tidak boleh kosong!",
                    },
                ],
            },
            weekly: [
                {
                    label: 'W1',
                    name: 'w1',
                },
                {
                    label: 'W2',
                    name: 'w2',
                },
                {
                    label: 'W3',
                    name: 'w3',
                },
                {
                    label: 'W4',
                    name: 'w4',
                },
                {
                    label: 'W5',
                    name: 'w5',
                },
                {
                    label: 'W6',
                    name: 'w6',
                },
            ],
            daily: [
                {
                    label: 'Senin',
                    name: 'senin',
                },
                {
                    label: 'Selasa',
                    name: 'selasa',
                },
                {
                    label: 'Rabu',
                    name: 'rabu',
                },
                {
                    label: 'Kamis',
                    name: 'kamis',
                },
                {
                    label: 'Jumat',
                    name: 'jumat',
                },
                {
                    label: 'Sabtu',
                    name: 'sabtu',
                },
                {
                    label: 'Minggu',
                    name: 'minggu',
                },
            ],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const disabledDate = current => {
            return current && current < moment().startOf('month')
        }

        const handleOk = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => {});

            if (!form.id) {
                form.post(state.endpoint.current)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil menyimpan')

                        handleModalCancel()
                        emit('success', data)
                    })
            } else {
                form.put(`${state.endpoint.current}/${form.id}`)
                    .then(({ data }) => {
                        if (data === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil memperbarui')

                        handleModalCancel()
                        emit('success', data)
                    })
            }

        }

        const checkVisitPlanSalesman = () => {
            apiClient.post(state.endpoint.check, form)
                .then(({ data }) => {
                    if (!isEmpty(data)) {
                        data.forEach(item => {
                            form.weekly = item.weekly
                            form.daily = item.daily
                        })
                    } else {
                        
                        // reset weekly and daily visit plan
                        form.weekly =  {
                            w1: false,
                            w2: false,
                            w3: false,
                            w4: false,
                            w5: false,
                            w6: false,
                        }

                        form.daily =  {
                            senin: false,
                            selasa: false,
                            rabu: false,
                            kamis: false,
                            jumat: false,
                            sabtu: false,
                            minggu: false,
                        }
                    }
                })
        }

        // handle func vue
        onMounted(() => {
            form.customer_id = props.item.id_toko
            form.salesman_id = toInteger(props.item.id_salesman)
            form.month = props.item.tanggal

            if (props.item.type_kunjungan) {
                form.visit_type_id = props.item.type_kunjungan.map(e => e.id)
            }

            if (!form.id) {
                form.reset()
            }
        })

        watch(() => pick(form, ['month', 'customer_id', 'salesman_id']), async (newVal, oldVal) => {
            if (!form.id) {
                checkVisitPlanSalesman()
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            disabledDate,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';

.ant-radio {
  .ant-radio-primary.ant-radio-button-wrapper-checked:not([data-vb-theme='default']) {
    color: #ffffff;
    background: #4b7cf3;
    border-color: #4b7cf3;
    box-shadow: -1px 0 0 0 #4b7cf3;
  }

  .ant-radio-danger.ant-radio-button-wrapper-checked:not([data-vb-theme='default']) {
    color: #ffffff;
    background: #f5222e;
    border-color: #f5222e;
    box-shadow: -1px 0 0 0 #f5222e;
  }
}
</style>
